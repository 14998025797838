import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store/store';
import VueAxios from 'vue-axios'
import Axios from 'axios'
import IdleVue from "idle-vue";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib";


Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.config.productionTip = false
Vue.use(VueAxios, Axios)

Axios.defaults.baseURL = process.env.VUE_APP_URI_API;

var token = localStorage.getItem('token');
if(token){
  Axios.defaults.headers.common['x-token'] = token;
}


const eventsHub = new Vue();


Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1800000 // 30 minutos,
  
});

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
