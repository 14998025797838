<template>
  <v-app>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>


export default {
  components: {  },
  name: "App",

  data: () => ({
    drawer: false
  }),

};
</script>
