import Vue from "vue";
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex)

const vuexPersist = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage
  })

const store = new Vuex.Store({
    plugins: [vuexPersist.plugin],
    state: {        
        username: null,
        auth: null,
        useremail: null,
        img: null,
        rol: null,
        token : null,
        idleVue : null,
      },
    mutations: {
      doLogin(state, user) {
        state.auth = true;
        state.username = user.name;
        state.useremail = user.email;
        state.img = user.img;
        state.rol = user.rol;
        state.token = user.token;
      },
      doLogout(state) {
        state.auth = false;
        state.username = null;
        state.useremail = null;
        state.img = null;
        state.token = null;
        state.rol = null;
      }
    },
    actions: {
      doLogin({ commit }, user) {
        commit("doLogin", user);        
      },
      doLogout({ commit }) {
        commit("doLogout");
      }
    },
    
})




export default store;