import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '../store/store'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',    
    component: HomeView,
    meta : {
      requiresAuth: true
    },
      children:[
        {
          path: '/',
          name: 'welcome',
          component: () => import('@/components/Welcome.vue'),
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/components/Register.vue')
        },
        {
          path: '/equipos',
          name: 'equipos',
          component: () => import('@/components/Equipos.vue')
        },
        {
          path: '/receive',
          name: 'receive',
          component: () => import('@/components/Receive.vue')
        },
        {
          path: '/deliver',
          name: 'deliver',
          component: () => import('@/components/Deliver.vue')
        },
        {
          path: '/technical-service',
          name: 'technical-service',
          component: () => import('@/components/Service.vue')
        },
        {
          path: '/incidence',
          name: 'incidence',
          component: () => import('@/components/Incidence.vue')
        },
        {
          path: '/register-internal-user',
          name: 'register-internal-user',
          meta: {
            requiresRole : true,
            rol : "Administrador"
          },
          component: () => import('@/components/InternalUser.vue')
        },
        {
          path: '/forms',
          name: 'forms',
          component: () => import('@/components/Forms.vue')
        }
      ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '*',
    component: () => import('../views/Error404.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LogIn.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {    
    if (store.state.auth) {
      if (to.matched.some((record) => record.meta.requiresRole)) {
        let role = to.meta.rol;
        
        if (store.state.rol == role) {
          next();
        } else {
          next({ name: "welcome" });
        }
      } else {
        next();
      }      
    } else {
      next({ name: "login" });
    }
  } else {
    next();
  }
});

export default router

