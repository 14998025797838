<template>
  <v-app>
  <v-navigation-drawer  app v-model="drawer" temporary width="400px">
      <Slidebar />
    </v-navigation-drawer>

  <v-app-bar app dark src="../assets/ppt..pptx.png"    
    >      
      <v-app-bar-nav-icon @click="drawer=true"></v-app-bar-nav-icon>

      <v-app-bar-title>Control de Inventario</v-app-bar-title>

      <v-spacer></v-spacer>
      
      
      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>           
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item

            @click="cerrarSesion"
          >
            <v-list-item-title>Cerrar sesion</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

  <v-main  style="padding: 0px 0px 0px;">
      <router-view />    
  </v-main>
</v-app>
</template>

<script>
import Slidebar from "@/components/Slidebar.vue";
import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",

  components: {
    Slidebar,
  },
  data: () => ({
    drawer: false,
    
  }),
  computed: mapState({
    isIdle: state => state.idleVue.isIdle,
 
  }),
  watch: {
    isIdle() {
      if(this.isIdle == true)
      this.cerrarSesion()
    }
  },
  methods: {
    cerrarSesion(){      
      localStorage.removeItem("token");
      localStorage.removeItem("my-app");
      this.$store.dispatch("doLogout");
      this.$router.push({ path: "/login" });
    }
  }
};
</script>
<style scoped>
.center {
  text-align: center;
}

</style>
