<template>
  <v-container>
    <v-list>
      <v-list-item link to="/">
        <v-list-item-avatar>
          <v-img :src="img"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> {{name}} </v-list-item-title>
          <v-list-item-subtitle>{{email}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-group :value="false" prepend-icon="mdi-monitor-multiple" active-class="deep-purple--text text--accent-4">
        <template v-slot:activator>
          <v-list-item-title>Equipo</v-list-item-title>
        </template>
        <v-list-item to="/forms" style="margin-left:5%" active-class="deep-purple--text text--accent-4">
          <v-list-item-icon>
            <v-icon>mdi-cellphone-link</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Registrar Nuevo</v-list-item-title>
        </v-list-item>
        <v-list-item to="/equipos" style="margin-left:5%" active-class="deep-purple--text text--accent-4">
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Lista de Equipos</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item-group
        
        active-class="deep-purple--text text--accent-4"
      >
        <v-list-item to="/receive">
          <v-list-item-icon>
            <v-icon>mdi-folder-download</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Usuarios Con Equipos Asignados</v-list-item-title>
        </v-list-item>

        <v-list-item to="/deliver">
          <v-list-item-icon>
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Entregar Equipo</v-list-item-title>
        </v-list-item>
        <v-list-item to="/register-internal-user" v-if="rol=='Administrador'">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Administración de Usuarios</v-list-item-title>
        </v-list-item>           
      </v-list-item-group>
    </v-list>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Slidebar",
  value: false,
  computed: mapState({
    name: state => state.username,
    email: state => state.useremail,
    img: state => state.img,
    rol: state => state.rol
  })
};
</script>
